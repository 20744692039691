var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-2-mobile is-4-tablet is-3-desktop"},[_c('br'),_c('aside',{staticClass:"menu"},[_c('ul',{staticClass:"menu-list"},[_c('li',[_c('router-link',{class:{ active: this.currentActive === 'my-booking' },attrs:{"to":"/my-booking"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'suitcase']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  My booking")])],1)],1),_c('li',[_c('router-link',{class:{ active: this.currentActive === 'contact' },attrs:{"to":"/contact"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'map-signs']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  Contact")])],1)],1),_c('li',[_c('router-link',{class:{ active: this.currentActive === 'bbq-fun' },attrs:{"to":"/bbq-fun"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'burn']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  BBQ fun")])],1)],1),_c('li',[_c('router-link',{class:{
            active: this.currentActive === 'picnic-basket',
          },attrs:{"to":"/picnic-basket"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'shopping-basket']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  Picnic basket")])],1)],1),_c('li',[_c('router-link',{class:{
            active: this.currentActive === 'dining-options',
          },attrs:{"to":"/dining-options"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'utensils']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  Dining options")])],1)],1),_c('li',[_c('router-link',{class:{
            active: this.currentActive === 'booking-info',
          },attrs:{"to":"/booking-info"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'info-circle']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  Booking information")])],1)],1),_c('li',[_c('router-link',{class:{ active: this.currentActive === 'weather' },attrs:{"to":"/weather"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'cloud']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  Weather")])],1)],1),_c('li',[_c('a',{class:{ active: this.currentActive === 'facebook' },attrs:{"href":"https://www.facebook.com/","target":"_blank"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fab', 'facebook-square']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  Facebook")])],1)]),_c('li',[_c('router-link',{class:{
            active: this.currentActive === 'tripadvisor',
          },attrs:{"to":"/tripadvisor"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'hotel']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  TripAdvisor")])],1)],1),_c('li',[_c('router-link',{class:{
            active: this.currentActive === 'holiday-check',
          },attrs:{"to":"/holiday-check"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fas', 'check-double']}}),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("  Holiday check")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }