<template>
  <!-- footer -->
  <footer class="footer">
    <div class="content">
      <p>© Copyright 2021 Pre-Check-In system. All Rights Reserved</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
